<header>
  <div class="container">
    <div class="header-container">
      <a routerLink="/home" class="logo">
        <img [src]="'../../../assets/images/' + logoImageName" alt="Madfu" />
      </a>

      <button class="menu-toggle" id="menu-toggle" (click)="toggleMenu()">
        <img
          [src]="
            '../../../assets/images/' +
            (pageTitle ? 'menu-menu.svg' : 'menu.svg')
          "
          alt=""
        />
      </button>

      <ul class="nav-menu" [ngClass]="{ active: menuOpen }">
        <li>
          <a routerLink="/about-us" routerLinkActive="active">{{
            "about-us" | translate
          }}</a>
        </li>
        <li>
          <a routerLink="/blogs" routerLinkActive="active">{{
            "blogs-and-news" | translate
          }}</a>
        </li>
        <li>
          <a routerLink="/be-partner" routerLinkActive="active">{{
            "register-as-a-partner" | translate
          }}</a>
        </li>
      </ul>
    </div>
  </div>
</header>
