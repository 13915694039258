import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ComplaintsService {
  constructor(private http: HttpClient) {}

  getIncidentTypes(type: number) {
    return this.http.get(
      `${environment.BackEndURL}/api/user/Incidents/GetIncidentTypes?ReportTypeId=${type}`
    );
  }

  createComplaint(data: any) {
    return this.http.post(
      `${environment.BackEndURL}/api/user/Incidents/create`,
      data
    );
  }
}
