<div class="complains-page">
  <div class="complaints-cover">
    <div class="container">
      <div class="complaints-cover-widget d-flex">
        <div>
          <h1>{{ "COMPLAINTS_TITLE" | translate }}</h1>
          <p>{{ "COMPLAINTS_SUBTITLE" | translate }}</p>
        </div>
        <img src="../../../assets/images/complaints-img.svg" alt="" />
      </div>
    </div>
  </div>

  <div class="official-channels">
    <div class="container">
      <div class="official-channels-widget">
        <h2>{{ "OFFICIAL_MADFU_CHANNELS" | translate }}</h2>
        <div class="contact">
          <div class="contact-item">
            <span>{{ "FREE_UNIFIED_NUMBER" | translate }}</span>
            <p>8001111316</p>
          </div>
          <div class="contact-item">
            <span>{{ "WHATSAPP" | translate }}</span>
            <p>966115063336</p>
          </div>
        </div>
        <img
          class="main-img"
          src="../../../assets/images/official-m.svg"
          alt=""
        />
      </div>
    </div>
  </div>

  <div class="container">
    <app-complains-form></app-complains-form>
  </div>

  <div class="main-contact-channels">
    <div class="container">
      <div class="main-contact-channels-widget">
        <p class="main-text">
          {{ "ESCALATION_MESSAGE" | translate }}
        </p>
        <div class="main-contact-wrapper">
          <ul class="location-and-call-widgets">
            <li>
              <img src="../../../assets/images/official-buliding.svg" alt="" />
              <p class="txt-bold" style="width: 65%">
                {{ "COMPLAINT_ADDRESS" | translate }}
              </p>
              <span class="txt-light" style="width: 75%; display: flex">
                {{ "SERVICE_HOURS" | translate }}
              </span>
            </li>
            <li>
              <img src="../../../assets/images/official-call.svg" alt="" />
              <p class="txt-light">
                {{ "CONTACT_INFO.TELEPHONE" | translate }}
              </p>
              <span class="txt-bold">8001256666</span>
            </li>
          </ul>
          <ul class="site-and-sms-widgets">
            <li>
              <img src="../../../assets/images/official-global.svg" alt="" />
              <p class="txt-bold">
                {{ "CONTACT_INFO.WEBSITE" | translate }}:
                <a href="https://www.sama.gov.sa" target="_blank">{{
                  "CONTACT_INFO.WEBSITE_URL" | translate
                }}</a>
              </p>
              <span class="txt-light">
                {{ "CONTACT_INFO.FINANCIAL_PROTECTION" | translate }}:
                <a href="https://www.sama.gov.sa" target="_blank">{{
                  "CONTACT_INFO.WEBSITE_URL" | translate
                }}</a>
              </span>
            </li>
            <li>
              <img src="../../../assets/images/official-sms.svg" alt="" />
              <p class="txt-light address">
                {{ "CONTACT_INFO.ADDRESS" | translate }}
              </p>

              <span class="txt-bold">{{
                "CONTACT_INFO.MAILING_ADDRESS" | translate
              }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
