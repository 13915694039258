import { Component, OnInit } from '@angular/core';
import { ChangeLangService } from './services/change-lang/change-lang.service';
import { NavigationEnd, Router } from '@angular/router';
import { LoaderService } from './services/helpers/Loader.Service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'landing-page';
  currentPageName: string = '';
  currentLang = localStorage.getItem('lang') || 'ar';
  isLoading = false;

  constructor(
    private changeLang: ChangeLangService,
    private router: Router,
    private loaderService: LoaderService
  ) {
    this.changeLang.changeLangage(this.currentLang, 'appComponent');
  }

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe((e) => {
      this.isLoading = e.valueOf();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updatePageName(this.router.url);
      }
    });
  }
  updatePageName(url: string) {
    switch (url) {
      case '/home':
        this.currentPageName = 'Home';
        break;
      default:
        this.currentPageName = '';
        break;
    }
  }
}
