import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BePartnerService {
  constructor(private http: HttpClient) {}

  bePartner(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const url = `${environment.BackEndURL}/api/CRM/Merchants/CreateLead`;

    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return this.http.post(url, formData);
  }
}
