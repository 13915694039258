import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { BePartnerService } from './services/be-partner.service';
import { ToastrService } from 'ngx-toastr';
import { CategoriesService } from '../home/service/categories.service';
import { partner } from 'src/app/interfaces/category';
import { TranslateService } from '@ngx-translate/core';
import { TokenInitService } from 'src/app/services/token-init.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss'],
})
export class PartnerComponent implements OnInit {
  discoverLink = 'https://app.adjust.com/1fz1h1en';
  merchantInformationForm?: FormGroup;
  storeRepresentativeInformationForm?: FormGroup;
  attachmentsForm?: FormGroup;
  captchaResponse: any;
  storePartners: partner[] = [];
  onlinePartners: partner[] = [];
  growthData: any;
  currentLang = localStorage.getItem('lang');
  loading = false;
  step = 1;

  customOptionsSlider: any = {
    loop: true,
    margin: 20,
    nav: true,
    autoplay: false,
    rtl: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  constructor(
    private bePartnerService: BePartnerService,
    private toaster: ToastrService,
    private categoriesService: CategoriesService,
    private translate: TranslateService,
    private tokenInitService: TokenInitService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById('defaultOpen')!.click();
    }, 500);

    this.getStorePartnersData();
    this.getOnlinePartnersData();
    this.getGrowthTogetherData();
  }

  initForm() {
    this.initiateMerchantInformationForm();
    this.initStoreRepresentativeInformationForm();
    this.initFreelancerInformationForm();
  }

  initiateMerchantInformationForm() {
    this.merchantInformationForm = new FormGroup({
      merchantName: new FormControl('', [Validators.required]),
      brandName: new FormControl('', [Validators.required]),
      numberOfBranches: new FormControl(null, [
        Validators.required,
        this.numericValidator,
      ]),
      commercialRegistrationNumber: new FormControl(null, [
        Validators.required,
      ]),
    });
  }

  initStoreRepresentativeInformationForm() {
    this.storeRepresentativeInformationForm = new FormGroup({
      relationshipToTheStore: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      idNumber: new FormControl('', [Validators.required]),
      dateOfBirth: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl('', [
        Validators.required,
        this.numericValidator,
      ]),
      freelancingDocumentNumber: new FormControl(''),
    });
  }

  initFreelancerInformationForm() {
    this.attachmentsForm = new FormGroup({
      commercialRegistration: new FormControl(null, [Validators.required]),
      vatCertificate: new FormControl(null),
      merchantBankAccount: new FormControl(null, [Validators.required]),
      signatoriesPersonalId: new FormControl(null, [Validators.required]),
      municipalityLicense: new FormControl(null),
      eCommerceAuthenticationCertificate: new FormControl(null),
      // freelancerDocument: new FormControl(null),
    });
  }

  resetForm() {
    this.merchantInformationForm?.reset();
    this.storeRepresentativeInformationForm?.reset();
    this.attachmentsForm?.reset();
  }

  getStorePartnersData() {
    this.categoriesService.getStorePartners().subscribe((response: any) => {
      this.storePartners = response;
    });
  }
  getOnlinePartnersData() {
    this.categoriesService.getOnlinePartners().subscribe((response: any) => {
      this.onlinePartners = response;
    });
  }

  numericValidator(control: AbstractControl): ValidationErrors | null {
    const number = control.value;
    const numericRegex = /^[0-9]*$/;
    if (numericRegex.test(number)) {
      return null;
    }
    return { invalidNumber: true };
  }

  nextButtonHandler() {
    this.step += 1;
  }

  backButtonHandler() {
    this.step -= 1;
  }

  bePartnerFormMethod() {
    this.loading = true;

    this.tokenInitService.tokenInit().subscribe({
      next: (response: any) => {
        localStorage.setItem('token', response.token);
        const data = {
          ...this.merchantInformationForm?.value,
          ...this.storeRepresentativeInformationForm?.value,
          ...this.attachmentsForm?.value,
        };

        this.bePartnerService.bePartner(data).subscribe({
          next: () => {
            this.handleSuccess();
          },
          error: (error) => {
            this.loading = false;
            this.handleError(error);
          },
        });
      },
    });
  }

  private handleSuccess() {
    this.step = 1;
    this.resetForm();
    this.loading = false;

    this.toaster.success(
      '',
      this.translate.instant('form-submitted-successfully')
    );
  }

  private handleError(error: any) {
    if (error.error?.errors) {
      const errors = error.error.errors;
      Object.keys(errors).forEach((key) => {
        const errorMessage = `${key}: ${errors[key].join(', ')}`;
        this.toaster.error(
          errorMessage,
          this.translate.instant('validation-error')
        );
      });
    } else {
      const errorMessage = error.error.detail || error.error.message;
      this.toaster.error(
        errorMessage,
        this.translate.instant('something-went-wrong')
      );
    }
  }

  getGrowthTogetherData() {
    this.categoriesService.getGrowthData().subscribe((response: any) => {
      this.growthData = response;
    });
  }

  openItem(evt: MouseEvent, itemName: string): void {
    // Declare all variables
    let i: number;
    let tabcontent: HTMLCollectionOf<Element>;
    let tablinks: HTMLCollectionOf<Element>;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName('tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      (tabcontent[i] as HTMLElement).style.display = 'none';
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName('tablinks');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(' active', '');
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(itemName)!.style.display = 'block';
    (evt.currentTarget as HTMLElement).className += ' active';
  }
}
