// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BackEndURL: 'https://api-gcp.madfu.com.sa',
  username: 'bnplrootusername',
  password: 'L8nGq7edHMJQgoZTZInesy6ZmedMtZNSHQ7c7d4qbozrr',
  apikey: 'a712d3ba-7ce8-11eb-8e6f-0',
  appcode: 'BNPL01',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
