import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class TokenInitService {
  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {}

  tokenInit() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    const systemInfo = `${deviceInfo.browser}(${deviceInfo.browser_version}) ${deviceInfo.os}`;

    const UUID = uuid();

    return this.http.post(environment.BackEndURL + '/user/token/init', {
      uuid: UUID,
      systemInfo: systemInfo,
    });
  }
}
