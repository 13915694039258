import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxDropdownConfig } from 'ngx-select-dropdown';
import { ToastrService } from 'ngx-toastr';
import { ComplaintsService } from 'src/app/pages/complaints/services/complaints.service';
import { TokenInitService } from 'src/app/services/token-init.service';

@Component({
  selector: 'app-complains-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  complaintsForm?: FormGroup;
  FORM_TYPES = FORM_TYPES;
  selectedFormType: FORM_TYPES = FORM_TYPES.DEFECT;
  fraudTypes: any = [];
  defectTypes: any = [];
  loading = false;

  dropdownConfig: NgxDropdownConfig = {};

  constructor(
    private complaintsService: ComplaintsService,
    private tokenInitService: TokenInitService,
    private translate: TranslateService,
    private toaster: ToastrService
  ) {
    this.dropdownConfig = {
      displayKey: 'name',
      placeholder: this.translate.currentLang === 'ar' ? 'اختر' : 'Select',
    };

    this.complaintsForm = new FormGroup({
      reporterName: new FormControl('', [Validators.maxLength(50)]),
      reporterMobile: new FormControl('', [
        Validators.maxLength(15),
        Validators.pattern('^[0-9]*$'),
      ]),
      reporterEmail: new FormControl('', [
        Validators.maxLength(254),
        Validators.email,
      ]),
      suspectName: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      suspectProfession: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      suspectMobile: new FormControl('', [
        Validators.maxLength(15),
        Validators.pattern('^[0-9]*$'),
      ]),
      incidentTypeId: new FormControl(null, [Validators.required]),
      incidentDescription: new FormControl('', [
        Validators.required,
        Validators.maxLength(1000),
      ]),
    });
  }

  get controls() {
    return this.complaintsForm?.controls;
  }

  get incidentTypes() {
    return this.selectedFormType === FORM_TYPES.DEFECT
      ? this.defectTypes
      : this.fraudTypes;
  }

  ngOnInit(): void {
    this.getIncidentTypes();
  }

  getIncidentTypes() {
    this.tokenInitService.tokenInit().subscribe({
      next: (response: any) => {
        localStorage.setItem('token', response.token);

        this.complaintsService
          .getIncidentTypes(FORM_TYPES.DEFECT)
          .subscribe((response: any) => {
            this.defectTypes = response.data;
          });

        this.complaintsService
          .getIncidentTypes(FORM_TYPES.FRAUD)
          .subscribe((response: any) => {
            this.fraudTypes = response.data;
          });
      },
    });
  }

  selectFormType(formType: FORM_TYPES) {
    this.selectedFormType = formType;
    this.complaintsForm?.patchValue({
      incidentTypeId: null,
    });
  }

  submitComplaintsForm() {
    this.loading = true;

    this.complaintsService
      .createComplaint({
        ...this.complaintsForm?.value,
        incidentTypeId: this.complaintsForm?.value?.incidentTypeId?.id,
      })
      .subscribe({
        next: () => {
          this.complaintsForm?.reset();
          this.loading = false;
          this.toaster.success(
            '',
            this.translate.instant('form-submitted-successfully')
          );
        },
        error: (error) => {
          this.loading = false;
          this.handleError(error);
        },
      });
  }

  private handleError(error: any) {
    let errorTitle = this.translate.instant('something-went-wrong');
    let errorMessage = '';

    if (error.status === 422 && error.error?.errors) {
      const errors = error.error.errors;
      const firstErrorKey = Object.keys(errors)[0];
      errorMessage = firstErrorKey + ': ' + errors[firstErrorKey].join(', ');
      errorTitle = this.translate.instant('validation-error');
    }

    this.toaster.error(errorMessage, errorTitle);
  }
}

enum FORM_TYPES {
  DEFECT = 1,
  FRAUD = 2,
}
